// @flow
import Rectangle from './Rectangle';
import {ArrowDown, ArrowUp, ArrowRight, ArrowLeft, Gap} from 'assets';
/**
 *
 *
 * @export
 * @class Door
 * @extends {Rectangle}
 */
export default class Door extends Rectangle {
    hangSize: number;
    strokeColour: string;
    rectWidth: number;
    rectHeight: number;
    xOffset: number;
    yOffset: number;
    hingeDirection: string;

    /**
     * Creates an instance of Door.
     * @memberof Door
     */
    constructor() {
        super();
        this.hangSize = 2;
    }

    /**
     *
     *
     * @param {number} backgroundXOffset
     * @param {number} backgroundYOffset
     * @param {number} rectWidth
     * @param {number} rectHeight
     * @param {(string | CanvasPattern)} exteriorPattern
     * @memberof Door
     */
    borders(
        backgroundXOffset: number,
        backgroundYOffset: number,
        rectWidth: number,
        rectHeight: number,
        exteriorPattern: string | CanvasPattern
    ) {
        if (
            this.options.borderRight + this.options.borderLeft >
            this.options.width
        ) {
            throw new Error('Invalid horizontal border width');
        }

        if (
            this.options.borderTop + this.options.borderBottom >
            this.options.height
        ) {
            throw new Error('Invalid vertical border width');
        }

        const sizeRatio: number =
            rectWidth < this.options.width
                ? rectWidth / this.options.width
                : this.options.width / rectWidth;
        const sizeRatioH: number =
            rectHeight < this.options.height
                ? rectHeight / this.options.height
                : this.options.height / rectHeight;

        const borderTop: number = Math.round(
            sizeRatio * this.options.borderTop
        );
        const borderRight: number = Math.round(
            sizeRatio * this.options.borderRight
        );
        const borderBottom: number = Math.round(
            sizeRatio * this.options.borderBottom
        );
        const borderLeft: number = Math.round(
            sizeRatio * this.options.borderLeft
        );

        const yOffset: number =
            this.options.midRailHorizontalAmount > 0
                ? Math.round(
                      sizeRatioH * this.options.midRailHorizontalHeight
                  ) / 2
                : 0;
        const xOffset: number =
            this.options.midRailVerticalAmount > 0
                ? Math.round(sizeRatio * this.options.midRailVerticalWidth) / 2
                : 0;

        const horizontalPositions: Array<number> = [
            ...this.options.midRailHorizontalPositions.map((position) =>
                Math.round(sizeRatioH * position)
            ),
            ...[rectHeight],
        ];
        const verticalPositions: Array<number> = [
            ...this.options.midRailVerticalPositions.map((position) =>
                Math.round(sizeRatio * position)
            ),
            ...[rectWidth],
        ];

        const glassSubPanels: Array<Array<boolean>> = Array.isArray(
            this.options.glassSubPanels
        )
            ? this.options.glassSubPanels.reverse()
            : [];

        horizontalPositions.forEach((horizontalPosition, i) => {
            let y: number = backgroundYOffset + rectHeight;
            let height: number = horizontalPosition * -1;

            if (i === 0) {
                y -= borderBottom;
                height += borderBottom + yOffset;
            } else {
                y -= horizontalPositions[i - 1] + yOffset;
                height += horizontalPositions[i - 1] + yOffset * 2;
            }

            if (i === horizontalPositions.length - 1) {
                height += borderTop - yOffset;
            }

            verticalPositions.forEach((verticalPosition, j) => {
                let width: number = verticalPosition;
                let x: number = backgroundXOffset;

                if (j === 0) {
                    x += borderLeft;
                    width -= borderLeft + xOffset;
                } else {
                    x += verticalPositions[j - 1] + xOffset;
                    width -= verticalPositions[j - 1] + xOffset * 2;
                }

                if (j === verticalPositions.length - 1) {
                    width -= borderRight - xOffset;
                }

                if (glassSubPanels.length) {
                    if (
                        glassSubPanels.length >= horizontalPositions.length &&
                        glassSubPanels[i].length >= verticalPositions.length
                    ) {
                        if (glassSubPanels[i][j]) {
                            if (
                                (this.options.barsVertical > 0 ||
                                    this.options.barsHorizontal) &&
                                this.options.barsWidth > 0
                            ) {
                                const barWidth: number = Math.round(
                                    sizeRatio * this.options.barsWidth
                                );
                                const barSectionWidth: number = Math.round(
                                    (width -
                                        barWidth * this.options.barsVertical) /
                                        (this.options.barsVertical + 1)
                                );
                                const barSectionHeight: number = Math.round(
                                    (height +
                                        barWidth *
                                            this.options.barsHorizontal) /
                                        (this.options.barsHorizontal + 1)
                                );

                                for (
                                    let verticalCount: number = 0;
                                    verticalCount <= this.options.barsVertical;
                                    verticalCount++
                                ) {
                                    for (
                                        let horizontalCount: number = 0;
                                        horizontalCount <=
                                        this.options.barsHorizontal;
                                        horizontalCount++
                                    ) {
                                        this.whiteLineRect(
                                            x +
                                                verticalCount *
                                                    barSectionWidth +
                                                verticalCount * barWidth,
                                            y +
                                                horizontalCount *
                                                    barSectionHeight -
                                                horizontalCount * barWidth,
                                            barSectionWidth,
                                            barSectionHeight
                                        );
                                    }
                                }
                            }

                            this.context.beginPath();
                            this.context.fillStyle = '#fff';
                            this.context.strokeStyle = this.strokeColour
                                ? this.strokeColour
                                : '#000000';
                            this.context.moveTo(x, y);
                            this.context.lineTo(x + width, y);
                            this.context.lineTo(x + width, y + height);
                            this.context.lineTo(x, y + height);
                            this.context.lineTo(x, y);

                            this.context.globalAlpha = this.opacity;
                            if (
                                !(
                                    (this.options.barsVertical > 0 ||
                                        this.options.barsHorizontal) &&
                                    this.options.barsWidth > 0
                                )
                            ) {
                                this.context.fill();
                            }
                            this.context.globalAlpha = 1;

                            this.context.stroke();
                        } else {
                            this.rectWithBackground(
                                x,
                                y,
                                width,
                                height,
                                exteriorPattern,
                                undefined,
                                false
                            );
                        }
                    } else {
                        const gi: number =
                            i === 0
                                ? 0
                                : i === horizontalPositions.length - 1
                                ? glassSubPanels.length - 1
                                : 1;
                        const gj: number =
                            j === 0
                                ? 0
                                : j === verticalPositions.length - 1
                                ? glassSubPanels[gi].length - 1
                                : 1;

                        if (glassSubPanels[gi][gj]) {
                            if (
                                (this.options.barsVertical > 0 ||
                                    this.options.barsHorizontal) &&
                                this.options.barsWidth > 0
                            ) {
                                const barWidth: number = Math.round(
                                    sizeRatio * this.options.barsWidth
                                );
                                const barSectionWidth: number = Math.round(
                                    (width -
                                        barWidth * this.options.barsVertical) /
                                        (this.options.barsVertical + 1)
                                );
                                const barSectionHeight: number = Math.round(
                                    (height +
                                        barWidth *
                                            this.options.barsHorizontal) /
                                        (this.options.barsHorizontal + 1)
                                );

                                for (
                                    let verticalCount: number = 0;
                                    verticalCount <= this.options.barsVertical;
                                    verticalCount++
                                ) {
                                    for (
                                        let horizontalCount: number = 0;
                                        horizontalCount <=
                                        this.options.barsHorizontal;
                                        horizontalCount++
                                    ) {
                                        this.whiteLineRect(
                                            x +
                                                verticalCount *
                                                    barSectionWidth +
                                                verticalCount * barWidth,
                                            y +
                                                horizontalCount *
                                                    barSectionHeight -
                                                horizontalCount * barWidth,
                                            barSectionWidth,
                                            barSectionHeight
                                        );
                                    }
                                }
                            }

                            this.context.beginPath();
                            this.context.fillStyle = '#fff';
                            this.context.strokeStyle = this.strokeColour
                                ? this.strokeColour
                                : '#000000';
                            this.context.moveTo(x, y);
                            this.context.lineTo(x + width, y);
                            this.context.lineTo(x + width, y + height);
                            this.context.lineTo(x, y + height);
                            this.context.lineTo(x, y);

                            this.context.globalAlpha = this.opacity;
                            if (
                                !(
                                    (this.options.barsVertical > 0 ||
                                        this.options.barsHorizontal) &&
                                    this.options.barsWidth > 0
                                )
                            ) {
                                this.context.fill();
                            }
                            this.context.globalAlpha = 1;

                            this.context.stroke();
                        } else {
                            this.rectWithBackground(
                                x,
                                y,
                                width,
                                height,
                                exteriorPattern,
                                undefined,
                                false
                            );
                        }
                    }
                }
            });
        });

        glassSubPanels.length && this.options.glassSubPanels.reverse();
    }

    /**
     *
     *
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @memberof Door
     */
    whiteLineRect(x: number, y: number, width: number, height: number) {
        this.context.beginPath();
        this.context.fillStyle = '#fff';
        this.context.moveTo(x, y);
        this.context.lineTo(x + width, y);
        this.context.lineTo(x + width, y + height);
        this.context.lineTo(x, y + height);
        this.context.lineTo(x, y);
        this.context.fill();
    }

    /**
     *
     *
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {(string | CanvasPattern)} [background='#fff']
     * @param {string} [strokeColour]
     * @param {boolean} fill
     * @param {number} opacityOverride
     * @memberof Door
     */
    rectWithBackground(
        x: number,
        y: number,
        width: number,
        height: number,
        background: string | CanvasPattern = '#fff',
        strokeColour?: string,
        fill?: boolean = true,
        opacityOverride?: number
    ) {
        x = x % 1 === 0 ? x + 0.5 : x;
        y = y % 1 === 0 ? y + 0.5 : y;

        this.context.beginPath();
        this.context.lineWidth = 1;
        this.context.strokeStyle = strokeColour
            ? strokeColour
            : this.strokeColour
            ? this.strokeColour
            : '#000000';

        this.context.rect(x, y, width, height);

        this.context.fillStyle = background;

        this.context.globalAlpha = opacityOverride
            ? opacityOverride
            : this.opacity;
        fill && this.context.fill();
        this.context.stroke();
        this.context.globalAlpha = 1;
    }

    /**
     *
     *
     * @param {number} xOffset
     * @param {number} yOffset
     * @param {number} rectWidth
     * @param {number} rectHeight
     * @param {string} edgePattern
     * @memberof Door
     */
    doorEdges(
        xOffset: number,
        yOffset: number,
        rectWidth: number,
        rectHeight: number,
        edgePattern: string | CanvasPattern
    ) {
        if (this.options.panelEdgeTop) {
            this.rectWithBackground(
                xOffset,
                yOffset - this.edgeWidth - 3,
                rectWidth,
                this.edgeWidth,
                edgePattern,
                '#000'
            );
        }

        if (this.options.panelEdgeRight) {
            this.rectWithBackground(
                xOffset + rectWidth + 3,
                yOffset,
                this.edgeWidth,
                rectHeight,
                edgePattern,
                '#000'
            );
        }

        if (this.options.panelEdgeBottom) {
            this.rectWithBackground(
                xOffset,
                yOffset + rectHeight + 3,
                rectWidth,
                this.edgeWidth,
                edgePattern,
                '#000'
            );
        }

        if (this.options.panelEdgeLeft) {
            this.rectWithBackground(
                xOffset - this.edgeWidth - 3,
                yOffset,
                this.edgeWidth,
                rectHeight,
                edgePattern,
                '#000'
            );
        }
    }

    /**
     *
     *
     * @return {*}  {Array<number>}
     * @memberof Door
     */
    pairDimension(): Array<number> {
        const [width, height] = this.dimension(
            this.options.width,
            this.options.height
        );

        const newWidth: number = width / 2.5;
        const newHeight: number = height / 2.5;

        return [newWidth, newHeight];
    }

    /**
     *
     *
     * @return {*} {Array<number>}
     * @memberof Door
     */
    lShapeDimension(): [Array<number>, number] {
        const height: number = this.options.height;
        const staticWidth: number =
            this.canvas.width - this.widthOffset * 2 - this.edgeWidth * 2;
        const totalWidth: number =
            this.options.widths.reduce((a, b) => a + b) +
            this.widthOffset * 3 +
            this.edgeWidth * 4;
        const widthRatio: number =
            totalWidth < staticWidth
                ? totalWidth / staticWidth
                : staticWidth / totalWidth;

        let rectWidth: Array<number> = this.options.widths.map((width) =>
            Math.round(width * widthRatio)
        );
        let rectHeight: number = Math.round(height * widthRatio);

        if (
            rectHeight + this.heightOffset * 2 + this.edgeWidth * 2 >
            this.canvas.height
        ) {
            rectHeight =
                this.canvas.height - this.heightOffset * 2 - this.edgeWidth * 2;
            const ratio: number =
                rectHeight < height ? rectHeight / height : height / rectHeight;

            rectWidth = this.options.widths.map((width) =>
                Math.round(width * ratio)
            );
        }

        return [rectWidth, rectHeight];
    }

    /**
     *
     * @param {any} options
     * @param {number} width
     * @param {number} originalWidth
     * @return {*}  {number[]}
     * @memberof Door
     */
    margins(options: Object, width: number, originalWidth: number): number[] {
        const margins = [];
        const ratio =
            width < originalWidth
                ? width / originalWidth
                : originalWidth / width;

        if (!isNaN(options.doorTop)) {
            margins.push(options.doorTop * ratio);
        } else {
            margins.push(0);
        }

        if (!isNaN(options.doorLeft)) {
            margins.push(options.doorLeft * ratio);
        } else {
            margins.push(0);
        }

        if (!isNaN(options.doorBottom)) {
            margins.push(options.doorBottom * ratio);
        } else {
            margins.push(0);
        }

        if (!isNaN(options.doorRight)) {
            margins.push(options.doorRight * ratio);
        } else {
            margins.push(0);
        }

        return margins;
    }

    /**
     *
     *
     * @param {number} margin
     * @param {string} image
     * @param {[]} imagePosition
     * @param {[]} textPosition
     * @param {boolean} needsLineBreak
     * @memberof Door
     */
    async drawMarginWithLabels(
        margin: number,
        image: string,
        imagePosition: number[],
        textPosition: number[],
        needsLineBreak: boolean = false
    ): Promise<void> {
        if (isNaN(margin)) {
            return;
        }

        const metric = this.options.metric ? this.options.metric : 'mm';
        const marginText = margin ? margin : 0;
        const labelText = `${marginText} ${metric}`;
        const labelImage = await this.image(image);

        const fontFamily = window.getComputedStyle(document.body).fontFamily;
        this.context.font = `bold 12px ${fontFamily}`;
        this.context.fillStyle = 'black';
        this.context.textAlign = 'center';

        this.context.drawImage(labelImage, imagePosition[0], imagePosition[1]);

        if (needsLineBreak) {
            this.context.fillText(
                labelText.length >= 6 ? `${marginText}` : labelText,
                textPosition[0],
                textPosition[1],
                45
            );

            if (labelText.length >= 6) {
                this.context.fillText(
                    metric,
                    textPosition[0],
                    textPosition[1] + 10,
                    45
                );
            }
        } else {
            this.context.fillText(
                labelText,
                textPosition[0],
                textPosition[1],
                60
            );
        }
    }

    /**
     *
     *
     * @memberof Door
     */
    async marginLabels(): Promise<void> {
        if (
            !this.options.hasOwnProperty('isQFP') ||
            (this.options.hasOwnProperty('isQFP') && this.options.isQFP)
        ) {
            return;
        }

        const marginLabelData = [
            {
                text: this.options.doorTop,
                image: ArrowDown,
                imagePosition: [
                    this.xOffset + this.rectWidth / 2 - 13,
                    this.yOffset - 35,
                ],
                textPosition: [
                    this.xOffset + this.rectWidth / 2,
                    this.yOffset - 35,
                ],
                needsLineBreak: false,
            },
            {
                text: this.options.doorBottom,
                image: ArrowUp,
                imagePosition: [
                    this.xOffset + this.rectWidth / 2 - 13,
                    this.yOffset + this.rectHeight + 10,
                ],
                textPosition: [
                    this.xOffset + this.rectWidth / 2,
                    this.yOffset + this.rectHeight + 40,
                ],
                needsLineBreak: false,
            },
            {
                text: this.options.doorLeft,
                image: ArrowRight,
                imagePosition: [
                    this.xOffset - 35,
                    this.yOffset + this.rectHeight / 2 - 10,
                ],
                textPosition: [
                    this.xOffset - 25,
                    this.yOffset + this.rectHeight / 2 + 25,
                ],
                needsLineBreak: true,
            },
            {
                text: this.options.doorRight,
                image: ArrowLeft,
                imagePosition: [
                    this.xOffset + this.rectWidth + 10,
                    this.yOffset + this.rectHeight / 2 - 10,
                ],
                textPosition: [
                    this.xOffset + this.rectWidth + 24,
                    this.yOffset + this.rectHeight / 2 + 25,
                ],
                needsLineBreak: true,
            },
        ];

        if (
            this.options.doorCount > 1 &&
            this.options.doorGap &&
            this.options.doorGap > 0
        ) {
            marginLabelData[0].imagePosition = [
                this.xOffset + this.rectWidth / 2 - 68,
                this.yOffset - 35,
            ];

            marginLabelData[0].textPosition = [
                this.xOffset + this.rectWidth / 2 - 26,
                this.yOffset - 20,
            ];
            marginLabelData[0].needsLineBreak = true;

            const imagePositionYoffset =
                this.options.isDrawerDoor && !this.options.isOven ? 11 : 41;
            const textPositionYoffset =
                this.options.isDrawerDoor && !this.options.isOven
                    ? this.yOffset + 10
                    : this.yOffset - 20;

            marginLabelData.push({
                text: this.options.doorGap,
                image: Gap,
                imagePosition: [
                    this.xOffset + this.rectWidth / 2,
                    this.yOffset - imagePositionYoffset,
                ],
                textPosition: [
                    this.xOffset + this.rectWidth / 2 + 54,
                    textPositionYoffset,
                ],
                needsLineBreak: true,
            });
        }

        marginLabelData.forEach((data) => {
            this.drawMarginWithLabels(
                data.text,
                data.image,
                data.imagePosition,
                data.textPosition,
                data.needsLineBreak
            );
        });
    }

    /**
     *
     *
     * @param {(string | CanvasPattern)} exteriorPattern
     * @param {(string | CanvasPattern)} edgePattern
     * @memberof Door
     */
    drawDoor(
        exteriorPattern: string | CanvasPattern,
        edgePattern: string | CanvasPattern
    ) {
        this.hingeDirection = this.options.hingeDirection;
        if (this.hingeDirection == 'horizontal') {
            this.options.doorHang = -1;
        }

        let rectWidth;
        let rectHeight;

        if (
            this.options.doorType == 1 ||
            (this.options.doorType == 3 &&
                (!this.options.hasOwnProperty('widths') ||
                    (this.options.widths && this.options.widths.length == 0)))
        ) {
            const width: number = this.options.width;
            const height: number = this.options.height;

            [rectWidth, rectHeight] = this.dimension(width, height);
            const [top, left, bottom, right] = this.margins(
                this.options,
                rectWidth,
                this.options.width
            );

            this.rectWidth = rectWidth;
            this.rectHeight = rectHeight;
            this.xOffset =
                this.canvas.getBoundingClientRect().width / 2 -
                this.rectWidth / 2 +
                0.5;
            this.yOffset =
                this.canvas.getBoundingClientRect().height / 2 - rectHeight / 2;
            const ratio: number =
                this.rectHeight < this.options.height
                    ? this.rectHeight / this.options.height
                    : this.options.height / this.rectHeight;

            if (this.options.isDrawerDoor && !this.options.isOven) {
                const partionHeight = Math.round(
                    this.options.partitionHeight * ratio
                );

                this.rectWithBackground(
                    this.xOffset + left,
                    this.yOffset + top,
                    this.rectWidth - left - right,
                    this.rectHeight - (this.rectHeight - partionHeight),
                    exteriorPattern,
                    '#000'
                );

                if (this.options.rem_door_shelf_height) {
                    this.yOffset +=
                        (this.options.height -
                            this.options.rem_door_shelf_height) *
                        ratio;
                }
            }

            let voidWidth = 0;
            if (this.options.doorVoid) {
                voidWidth = ratio * this.options.doorVoid;

                if (
                    this.options.doorVoidSide &&
                    this.options.doorVoidSide == 'left'
                ) {
                    this.xOffset -= voidWidth;
                }
            }

            if (this.options.rem_door_shelf_height) {
                this.rectHeight = ratio * this.options.rem_door_shelf_height;
            }

            if (this.options.cabinet_total_opening_width) {
                const openingWidth =
                    ratio * this.options.cabinet_total_opening_width;

                if (
                    this.options.name &&
                    (this.options.name.toLowerCase().includes('left return') ||
                        this.options.name.toLowerCase().includes('return left'))
                ) {
                    const strokeColor = this.color(
                        this.xOffset,
                        this.yOffset,
                        this.rectWidth - openingWidth + voidWidth,
                        this.rectHeight,
                        '#cfcfcf',
                        '#242424'
                    );

                    this.rectWithBackground(
                        this.xOffset,
                        this.yOffset,
                        this.rectWidth - openingWidth + voidWidth,
                        this.rectHeight,
                        exteriorPattern,
                        strokeColor,
                        true
                    );

                    this.xOffset =
                        this.xOffset +
                        this.rectWidth -
                        openingWidth +
                        voidWidth;
                } else {
                    const strokeColor = this.color(
                        this.xOffset + openingWidth,
                        this.yOffset,
                        this.rectWidth - openingWidth + voidWidth,
                        this.rectHeight,
                        '#cfcfcf',
                        '#242424'
                    );

                    this.rectWithBackground(
                        this.xOffset + openingWidth,
                        this.yOffset,
                        this.rectWidth - openingWidth + voidWidth,
                        this.rectHeight,
                        exteriorPattern,
                        strokeColor,
                        true
                    );
                }

                this.rectWidth = openingWidth;
            }

            if (this.options.totalDoors > 1) {
                const doorGap = this.options.doorGap
                    ? ratio * this.options.doorGap
                    : 0;

                const doors = [
                    {
                        xOffset: this.xOffset + left,
                        yOffset: this.yOffset + top,
                        width: this.rectWidth / 2 - left - doorGap / 2,
                        height: this.rectHeight - top - bottom,
                    },
                    {
                        xOffset:
                            this.xOffset + this.rectWidth / 2 + doorGap / 2,
                        yOffset: this.yOffset + top,
                        width: this.rectWidth / 2 - right - doorGap / 2,
                        height: this.rectHeight - top - bottom,
                    },
                ];

                const hangName =
                    this.options.hangName &&
                    this.options.hangName.toLowerCase();

                if (
                    hangName &&
                    hangName.includes('leftbifold') &&
                    !hangName.includes('rightbifold')
                ) {
                    doors[0].width = this.rectWidth * 0.75 - left - doorGap / 2;

                    doors[1].xOffset =
                        this.xOffset + this.rectWidth * 0.75 + doorGap / 2;
                    doors[1].width =
                        this.rectWidth * 0.25 - right - doorGap / 2;
                } else if (
                    hangName &&
                    hangName.includes('rightbifold') &&
                    !hangName.includes('leftbifold')
                ) {
                    doors[0].width = this.rectWidth * 0.25 - left - doorGap / 2;

                    doors[1].xOffset =
                        this.xOffset + this.rectWidth * 0.25 + doorGap / 2;
                    doors[1].width =
                        this.rectWidth * 0.75 - right - doorGap / 2;
                }

                doors.forEach((door, index) => {
                    this.rectWithBackground(
                        door.xOffset,
                        door.yOffset,
                        door.width,
                        door.height,
                        exteriorPattern
                    );

                    this.strokeColour = this.color(
                        door.xOffset,
                        door.yOffset,
                        door.width,
                        door.height,
                        '#cfcfcf',
                        '#242424'
                    );

                    this.borders(
                        door.xOffset,
                        door.yOffset,
                        door.width,
                        door.height,
                        exteriorPattern
                    );

                    this.drawHang({
                        xOffset: door.xOffset,
                        yOffset: door.yOffset,
                        width: door.width,
                        height: door.height,
                        isRight: index == 1,
                        small: false,
                        horizontalHinge: false,
                        reverseDoorHang: this.options.doorHangReverse,
                    });
                });
            } else {
                this.rectWithBackground(
                    this.xOffset + left,
                    this.yOffset + top,
                    this.rectWidth - left - right,
                    this.rectHeight - top - bottom,
                    exteriorPattern
                );

                this.strokeColour = this.color(
                    this.xOffset + left,
                    this.yOffset + top,
                    this.rectWidth - left - right,
                    this.rectHeight - top - bottom,
                    '#cfcfcf',
                    '#242424'
                );

                this.borders(
                    this.xOffset + left,
                    this.yOffset + top,
                    this.rectWidth - left - right,
                    this.rectHeight - top - bottom,
                    exteriorPattern
                );

                this.drawHang({
                    xOffset: this.xOffset + left,
                    yOffset: this.yOffset + top,
                    width: this.rectWidth - left - right,
                    height: this.rectHeight - top - bottom,
                    isRight: this.options.doorHang === 1,
                    small: false,
                    horizontalHinge: this.hingeDirection == 'horizontal',
                    reverseDoorHang: this.options.doorHangReverse,
                });
            }

            this.doorEdges(
                this.xOffset + left,
                this.yOffset + top,
                this.rectWidth - left - right,
                this.rectHeight - top - bottom,
                edgePattern
            );

            this.marginLabels();
        } else {
            [rectWidth, rectHeight] = this.pairDimension();

            if (this.options.doorType == 3)
                [rectWidth, rectHeight] = this.lShapeDimension();
            else rectWidth = new Array(2).fill(rectWidth);

            this.rectHeight = rectHeight;

            this.yOffset =
                this.canvas.getBoundingClientRect().height -
                this.rectHeight -
                this.heightOffset;

            let previousWidth: number = 0;

            rectWidth.map(async (width, index) => {
                const xOffset: number =
                    index == 0
                        ? this.widthOffset
                        : previousWidth + this.widthOffset * 2;
                previousWidth = width;

                this.rectWithBackground(
                    xOffset,
                    this.yOffset,
                    width,
                    this.rectHeight,
                    exteriorPattern
                );

                this.strokeColour = this.color(
                    xOffset,
                    this.yOffset,
                    width,
                    this.rectHeight,
                    '#cfcfcf',
                    '#242424'
                );

                this.doorEdges(
                    xOffset,
                    this.yOffset,
                    width,
                    this.rectHeight,
                    edgePattern
                );
                this.borders(
                    xOffset,
                    this.yOffset,
                    width,
                    this.rectHeight,
                    exteriorPattern
                );

                this.options.doorType === 2 &&
                    this.options.doorHang === 1 &&
                    this.drawHang({
                        xOffset: xOffset,
                        yOffset: this.yOffset,
                        width: width,
                        height: this.rectHeight,
                        isRight: index !== 0,
                        small: true,
                        horizontalHinge: false,
                        reverseDoorHang: this.options.doorHangReverse,
                    });
            });

            const width: number =
                rectWidth.reduce((a, b) => a + b) + this.widthOffset;

            this.options.doorType >= 3 &&
                this.options.doorHang !== 2 &&
                this.drawHang({
                    xOffset: this.widthOffset,
                    yOffset: this.yOffset,
                    width: width,
                    height: this.rectHeight,
                    isRight: this.options.doorHang === 1,
                    small: true,
                    horizontalHinge: false,
                    reverseDoorHang: this.options.doorHangReverse,
                });
        }

        // WIP on adding/show/hide vertical midrails
        // Draw midrails inficator
        if (
            this.options.hasOwnProperty('midRailHorizontalPositions') &&
            this.options.hasOwnProperty('activeHorizontalMidrails')
        ) {
            const horizontalPositions = this.options.midRailHorizontalPositions;

            if (
                horizontalPositions.length > 0 &&
                this.options?.midRailIndicator
            ) {
                horizontalPositions.forEach((position, index) => {
                    if (index == this.options.activeHorizontalMidrails) {
                        this.drawMidrailIndicator(position);
                    }
                });
            }
        }

        if (
            this.options.hasOwnProperty('midRailVerticalPositions') &&
            this.options.hasOwnProperty('activeVerticalMidrails')
        ) {
            const verticalPositions = this.options.midRailVerticalPositions;

            if (
                verticalPositions.length > 0 &&
                this.options?.midRailIndicator
            ) {
                verticalPositions.forEach((position, index) => {
                    if (index == this.options.activeVerticalMidrails) {
                        this.drawMidrailIndicator(position, false);
                    }
                });
            }
        }
    }

    /**
     *
     * @param {number} position
     * @param {boolean} horizontal
     */
    drawMidrailIndicator(position: number, horizontal = true) {
        let ratio = this.rectHeight / this.options.height;
        if (!horizontal) {
            ratio = this.rectWidth / this.options.width;
        }

        const xOffset = horizontal ? 15 : 0;
        const yOffset = !horizontal ? 15 : 0;
        const yPosition = horizontal ? position * ratio : 0;
        const xPosition = !horizontal ? position * ratio : 0;

        const baseXOffset = horizontal ? 3 : 0;
        const baseYOffset = !horizontal ? 3 : 0;

        this.context.beginPath();
        this.context.strokeStyle = 'black';
        this.context.strokeWidth = 1;

        // draw vertical/horizontal line of the arrow
        this.context.moveTo(
            this.xOffset - xOffset,
            this.yOffset + this.rectHeight + 0.5 + yOffset
        );
        this.context.lineTo(
            this.xOffset - xOffset + xPosition,
            this.yOffset + this.rectHeight - yPosition + 0.5 + yOffset
        );
        this.context.stroke();
        // draw vertical/horizontal line of the arrow

        // draw base of the arrow
        this.context.moveTo(
            this.xOffset - (xOffset + baseXOffset),
            this.yOffset + this.rectHeight + (yOffset - baseYOffset) + 0.5
        );
        this.context.lineTo(
            this.xOffset - (xOffset - baseXOffset),
            this.yOffset + this.rectHeight + (yOffset + baseYOffset) + 0.5
        );
        this.context.stroke();
        // draw base of the arrow

        // draw left/bottom line of the arrow head
        this.context.moveTo(
            this.xOffset + xPosition - xOffset,
            this.yOffset + this.rectHeight - yPosition + yOffset + 0.5
        );
        this.context.lineTo(
            this.xOffset + xPosition - (xOffset + baseXOffset + baseYOffset),
            this.yOffset +
                this.rectHeight -
                yPosition +
                (yOffset + baseXOffset - baseYOffset) +
                0.5
        );
        this.context.stroke();
        // draw left/bottom line of the arrow head

        // draw right/top line of the arrow head
        this.context.moveTo(
            this.xOffset + xPosition - xOffset,
            this.yOffset + this.rectHeight + yOffset - yPosition + 0.5
        );
        this.context.lineTo(
            this.xOffset + xPosition - (xOffset - baseXOffset + baseYOffset),
            this.yOffset +
                this.rectHeight -
                yPosition +
                (yOffset + baseXOffset + baseYOffset) +
                0.5
        );
        this.context.stroke();
        // draw right/top line of the arrow head

        // draw line that goes across the preview
        this.context.strokeStyle = 'rgba(0,0,0,0.3)';
        if (horizontal) {
            this.context.moveTo(
                0,
                this.yOffset + this.rectHeight - yPosition - 0.5
            );
            this.context.lineTo(
                this.canvas.width,
                this.yOffset + this.rectHeight - yPosition - 0.5
            );
        } else {
            this.context.moveTo(this.xOffset + xPosition + 0.5, 0);
            this.context.lineTo(
                this.xOffset + xPosition + 0.5,
                this.canvas.height
            );
        }
        this.context.stroke();
        // draw line that goes across the preview

        this.context.font = '12px'; // Set the font size and family
        this.context.fillStyle = 'black'; // Set the text color
        this.context.textAlign = 'right';

        this.context.save();

        // Move the origin to the point where you want to draw the text
        if (horizontal) {
            this.context.translate(
                this.xOffset - 20,
                this.yOffset + this.rectHeight - yPosition + 2
            );

            // Rotate the context 90 degrees anticlockwise
            this.context.rotate(-Math.PI / 2);
        } else {
            this.context.translate(
                this.xOffset + xPosition - 1,
                this.yOffset + this.rectHeight + 26
            );
        }

        // Draw the text at the origin
        this.context.fillText(`${position}mm`, 0, 0);

        // Restore the context to its original state
        this.context.restore();
    }

    /**
     *
     * @param {number} opacity
     * @memberof Door
     */
    async draw(opacity?: number) {
        if (opacity) {
            this.opacity = opacity;
        }

        // Loading both images before starting to draw anything
        const isHorizontal: boolean =
            this.options.hasOwnProperty('horizontal') &&
            this.options.horizontal;

        const exteriorImage: Image | boolean = await this.image(
            this.options.exteriorMaterial
        );

        const edgeImage: Image | boolean = await this.image(
            this.options.edgeMaterial
        );

        try {
            const exteriorPattern: CanvasPattern = this.pattern(
                isHorizontal,
                exteriorImage
            );
            const edgePattern: CanvasPattern = this.pattern(
                isHorizontal,
                edgeImage
            );

            this.drawDoor(exteriorPattern, edgePattern);
        } catch (error) {
            console.log(error);
        }
    }
}
