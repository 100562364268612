import React, {useState, useCallback} from 'react';
import {BaseConfig} from 'config';
import {JobMenuItem} from 'shared/components/Header/JobMenuItem';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import useUI from 'shared/useUI';
import {SidebarStatus, SidebarBehaviour} from 'store/customer/UISlice';
import Sidebar, {useLeftSidebar} from 'shared/components/Sidebar';
import MenuButton from 'shared/components/MenuButton';
import {VariationResetButton} from 'shared/components/Header/VariationResetButton';
import {SubmitButton} from 'shared/components/Header/SubmitButton';
import styled from 'styled-components';

export const SidebarLeft = () => {
    const isSmallDevice = isDeviceSmall();
    const {isSmallScreenLayout, leftSidebar, leftSidebarBehaviour} = useUI();
    const {toggle} = useLeftSidebar();
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const handleNavRefChange = useCallback((ref: HTMLElement) => {
        const navRef = ref;
        if (navRef) {
            const hasVertScroll = navRef.scrollHeight > navRef.clientHeight;

            setHasVerticalScroll(hasVertScroll);
        }
    }, []);

    if (!isSmallDevice) {
        return (
            <Sidebar
                sidebarId="sidebar-left"
                className="sidebar-left"
                anchor="LEFT"
                behaviour={leftSidebarBehaviour}
                status={leftSidebar}
                showOverlays={false} // NOTE: fix this by testing the UI
                partialClose={leftSidebarBehaviour === SidebarBehaviour.TOGGLE}
                partialCloseWidth={isSmallScreenLayout ? 45 : 80}>
                <div className="sidebar-left-content">
                    <MenuButton status={leftSidebar} onClick={toggle} />
                    <NavContainer
                        ref={(ref) => handleNavRefChange(ref)}
                        className="nav-container"
                        style={
                            isSmallScreenLayout &&
                            leftSidebar === SidebarStatus.CLOSED &&
                            hasVerticalScroll
                                ? {
                                      overflowY: 'hidden',
                                      overflowX: 'hidden',
                                      paddingRight: 10,
                                  }
                                : {
                                      overflowX: 'hidden',
                                  }
                        }>
                        <JobMenuItem
                            jobMenu={BaseConfig.USER_CUSTOMER.jobMenu}
                            isCollapsed={
                                leftSidebar != SidebarStatus.OPEN &&
                                leftSidebarBehaviour === SidebarBehaviour.TOGGLE
                            }
                        />
                    </NavContainer>
                    <section className="sidebar-footer-left">
                        <SubmitButton
                            left={true}
                            showLabel={leftSidebar === SidebarStatus.OPEN}
                        />
                        <VariationResetButton />
                    </section>
                </div>
            </Sidebar>
        );
    } else {
        return null;
    }
};

const NavContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2vh 0 0 !important;
`;
