import React from 'react';
import {useAppContext} from 'contexts';
import {Link} from 'react-router-dom';
import {Icon} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

export const DashboardButton = () => {
    const {isLeftDashboardButtonVisible} = useAppContext();

    if (isLeftDashboardButtonVisible && !isDeviceSmall()) {
        return (
            <Link
                to="/v2"
                className="sidebar-floating-button sidebar-dashboard-button">
                <Icon iconName="Home.svg" />
            </Link>
        );
    } else {
        return null;
    }
};
