import React, {useMemo, useState, useCallback} from 'react';
import {
    SidebarRightBenchtop,
    SidebarRightProducts,
    SidebarRightRoom,
    SidebarRightSundries,
} from 'shared';
import {Icon, pdfOptionsOrder} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useJobContext, useAppContext} from 'contexts';
import {PDFMenu} from 'hooks';
import {Dropdown} from 'react-bootstrap';
import SideBarRightFooter from 'shared/components/Header/SideBarRightFooter';
import OutsideClickHandler from 'shared/components/Header/OutsideClickHandler';
import {SidebarRightBenchtopModule} from 'shared/components/Header/SidebarRightBenchtopModule';
import {SecondaryButton} from 'shared/components/SecondaryButton';
import {VariationResetButton} from 'shared/components/Header/VariationResetButton';
import {SubmitButton} from 'shared/components/Header/SubmitButton';
import {usePdfLoadingPrompt} from 'shared/helpers/usePdfLoadingPrompt';

export const CartContent = () => {
    const {job, actionButtonsEnabled} = useJobContext();
    const {userProfile} = useAppContext();
    const [showPdfDropdown, setShowPdfDropdown] = useState(false);
    const {viewJobPdf} = usePdfLoadingPrompt();

    const pdfDropDown = useMemo(() => {
        return {
            dropdown: [
                {
                    name: PDFMenu.JOB_DETAILS_PDF,
                    show:
                        userProfile.hasOwnProperty(
                            'showJobPropertiesTablePdf'
                        ) && userProfile.showJobPropertiesTablePdf
                            ? true
                            : false,
                    order: pdfOptionsOrder(
                        PDFMenu,
                        PDFMenu.JOB_DETAILS_PDF,
                        userProfile
                    ),
                    onClick: () => viewJobPdf(job.id),
                },
                {
                    name: PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                    show:
                        userProfile.hasOwnProperty(
                            'showOrderAcknowledgementPdf'
                        ) && userProfile.showOrderAcknowledgementPdf
                            ? true
                            : false,
                    link: `/quotePdf.php?jobId=${job.id}`,
                    order: pdfOptionsOrder(
                        PDFMenu,
                        PDFMenu.ORDER_ACKNOWLEDGEMENT_PDF,
                        userProfile
                    ),
                },
                {
                    name: PDFMenu.JOB_TOPDOWN_LAYOUT,
                    show:
                        job.hasOwnProperty('MaxLayoutStatus') &&
                        job.MaxLayoutStatus >= 2,
                    link: `/jobTopDownLayoutPDF.php?jobId=${job.id}`,
                    order: 4,
                },
            ],
        };
    }, [job, userProfile]);

    const onOutsideClickFunc = () => {
        setShowPdfDropdown(false);
    };

    const handleClick = useCallback((e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        setShowPdfDropdown((prevState) => !prevState); // Toggle dropdown state
    }, []); // Dependency array is empty, so this function won't change

    const handleBlur = useCallback((e) => {
        // Only hide if click outside of dropdown
        const clickNode =
            e.relatedTarget && e.relatedTarget.nodeName === 'A'
                ? e.relatedTarget
                : e.target;

        if (clickNode) {
            clickNode.click(); // Simulate click on the related target
        }

        setShowPdfDropdown(false); // Hide the dropdown
    }, []); // No dependencies, this will not change

    return (
        <>
            <section className="sidebar-content">
                <div className="detail-export">
                    <div>
                        <div className="jobId">
                            {`Job #${job.displayId ? job.displayId : ''}`}
                        </div>
                        <div className="jobTitle">{job.name}</div>
                    </div>

                    <div>
                        <OutsideClickHandler
                            onOutsideClick={onOutsideClickFunc}>
                            <Dropdown
                                className="table-option-dropdown pdfDropDownCart"
                                show={showPdfDropdown}
                                drop="down"
                                onBlur={handleBlur}>
                                <Dropdown.Toggle
                                    variant="link"
                                    className="button-blue btn-sm"
                                    onClick={handleClick}>
                                    <Icon iconName="Button-Create-PDF.svg" />
                                    <span>PDF</span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu renderOnMount={true}>
                                    {Object.keys(pdfDropDown).length > 0
                                        ? pdfDropDown.dropdown.map(
                                              (option, index) => {
                                                  if (
                                                      option.hasOwnProperty(
                                                          'show'
                                                      )
                                                  ) {
                                                      if (
                                                          typeof option.show ===
                                                              'boolean' &&
                                                          !option.show
                                                      )
                                                          return (
                                                              <div
                                                                  // No changes on this linr
                                                                  // eslint-disable-next-line react/no-array-index-key
                                                                  key={index}
                                                              />
                                                          );
                                                  }
                                                  return (
                                                      <Dropdown.Item
                                                          href={option?.link}
                                                          onClick={
                                                              option?.onClick
                                                          }
                                                          target="_blank"
                                                          // No changes on this linr
                                                          // eslint-disable-next-line react/no-array-index-key
                                                          key={index}>
                                                          {option.name}
                                                      </Dropdown.Item>
                                                  );
                                              }
                                          )
                                        : null}
                                </Dropdown.Menu>
                            </Dropdown>
                        </OutsideClickHandler>
                    </div>
                </div>
                {typeof job.id !== 'undefined' &&
                actionButtonsEnabled &&
                job.status != 5 ? (
                    <SecondaryButton
                        className="mt-3 p-1"
                        to={`/v2/job/${job.displayId}`}
                        $fullWidth>
                        <Icon iconName="Button-Edit.svg" />
                        <span>Edit Job Details</span>
                    </SecondaryButton>
                ) : null}

                {/* Right sidebar room table contents*/}
                <SidebarRightRoom />

                {/* Right sidebar product table contents*/}
                <SidebarRightProducts />

                {/* Right sidebar Benchtop table contents*/}
                <SidebarRightBenchtop />

                {/* Right sidebar Benchtop Module table contents*/}
                <SidebarRightBenchtopModule />

                {/* Right sidebar Sundries table contents*/}
                <SidebarRightSundries />
            </section>
            <section className="sidebar-footer">
                <SideBarRightFooter />
                <SubmitButton />
                <VariationResetButton />
            </section>
        </>
    );
};

export const Cart = () => {
    if (isDeviceSmall()) {
        return (
            <section className="mobile-cart sidebar sidebar-right">
                <CartContent />
            </section>
        );
    } else {
        return <></>;
    }
};
