import React, {useCallback} from 'react';
import {JobTable, jobSearch} from 'hooks';
import {Spinner, Row} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {useAppContext} from 'contexts';

/*
 *Initiate search when:
 * 1. Enter is pressed
 * 2. 3 second no activity
 * 3. 3 Characters minimum
 * 4. Cancel Request, When new keypress activity
 */
const SearchBar = () => {
    const {
        resultData,
        resetResults,
        callSearch,
        searchOnText,
        searchOnButton,
        isSearching,
    } = jobSearch();
    const {sideBarOverlays, searchInput, resultReceived} = useAppContext();

    const resetResultsHandler = useCallback(
        (conditionalReset = false) =>
            () => {
                if (conditionalReset) {
                    if (searchInput.current.value != '') {
                        resetResults(true);
                        searchInput.current.blur();
                    }
                } else {
                    resetResults(true);
                }
            },
        [resetResults]
    );

    return (
        <>
            <div
                className={`search_overlay ${
                    sideBarOverlays ? 'overlayOn' : 'overlayOff'
                }`}
                onClick={resetResultsHandler()}
            />
            <span className="search_box">
                <div className="search-container">
                    <input
                        className={`search expandright ${searchOnText}`}
                        id={`searchright`}
                        type={`search`}
                        autoComplete={`off`}
                        placeholder={`Search Job # or Job Name`}
                        onKeyDown={callSearch}
                        ref={searchInput}
                        onBlur={resetResultsHandler()}
                    />

                    <label
                        className={`s_button searchbutton ${searchOnButton}`}
                        htmlFor="searchright"
                        onClick={resetResultsHandler(true)}>
                        <span className="mglass">
                            <Icon iconName={`search-black.svg`} />
                        </span>
                    </label>
                    <div id="search_results" onKeyDown={resetResultsHandler()}>
                        {isSearching && (
                            <div id="search_loader">
                                <div id="search_spinner">
                                    <Spinner
                                        style={{
                                            width: '15px',
                                            height: '15px',
                                            color: '#AAA',
                                        }}
                                        animation="border"></Spinner>
                                    <span className="searchText">{`Searching...`}</span>
                                </div>
                            </div>
                        )}

                        {resultReceived &&
                            resultData.length == 0 &&
                            sideBarOverlays && (
                                <div id="search_message">
                                    <span className="searchText">
                                        {`No records found.`}
                                    </span>
                                </div>
                            )}

                        {resultReceived && resultData.length > 0 && (
                            <Row>
                                <JobTable
                                    title="Job Search"
                                    jobData={resultData}
                                    className="search-jobs"
                                    columns={['id', 'name', 'status']}
                                    link=""
                                    mobileRender={true}
                                    tableOrder={0}
                                    tablesWithValue={1}
                                    fromApi={false}
                                    api=""
                                />
                            </Row>
                        )}
                    </div>
                </div>
            </span>
        </>
    );
};

export {SearchBar};
