import React from 'react';
import {CBCImage} from 'shared/components/Image';
import useUI from 'shared/useUI';
import {SidebarStatus} from 'store/customer/UISlice';
import styled, {useTheme} from 'styled-components';

interface MenuButtonProps {
    onClick: () => void;
    status: string;
}

const MenuButton = ({onClick, status}: MenuButtonProps) => {
    const theme = useTheme();
    const {isSmallScreenLayout} = useUI();

    return (
        <a
            role="button"
            tabIndex={0}
            className="dashboard-link"
            onClick={onClick}
            style={{textDecoration: 'none', height: '70px'}}>
            <StyledDiv $isSmallDevice={isSmallScreenLayout}>
                <CBCImage
                    src={
                        status === SidebarStatus.OPEN
                            ? 'Header-Menu-Open.svg'
                            : 'Header-Menu.svg'
                    }
                    alt="Menu Icon"
                    color={theme.colors.headerIconColor.main}
                />
            </StyledDiv>
            {status === SidebarStatus.OPEN ? (
                <StyledLabel>Collapse Menu</StyledLabel>
            ) : null}
        </a>
    );
};

export default MenuButton;

const StyledLabel = styled.label`
    margin: 0;
    cursor: pointer;
`;

export const StyledDiv = styled.div<{$isSmallDevice?: boolean}>`
    ${({$isSmallDevice}) => {
        if ($isSmallDevice) {
            return `
                width: 45px !important;
            `;
        }
    }}
`;
