import {useCallback, useEffect, useState} from 'react';
import {useDialogContext} from 'contexts';
import {useGetJobPdfStatusQuery} from 'components/customer/Job/store/jobApi';

export const usePdfLoadingPrompt = () => {
    const {showDialog} = useDialogContext();
    const [jobId, setJobId] = useState<number>(null);
    const [timestamp, setTimestamp] = useState<number>(null);
    const [isFirstCall, setIsFirstCall] = useState(true);
    const {data, isLoading, isFetching} = useGetJobPdfStatusQuery(
        {jobId, timestamp},
        {skip: !jobId, pollingInterval: 5000}
    );

    const viewJobPdf = useCallback((jobId: number) => {
        setJobId(jobId);
        // cache buster
        setTimestamp(Date.now());
        setIsFirstCall(true);
    }, []);

    const openUrl = useCallback((jobId: number) => {
        setJobId(null);
        window.open(`/api/job/${jobId}/pdf/details`);
    }, []);

    useEffect(() => {
        // determines if pdf is available on first call
        if (isFirstCall && data && !isLoading && !isFetching) {
            setIsFirstCall(false);
            if (data && !data.isJobQueued) {
                openUrl(jobId);
            }
        }
    }, [isFirstCall, data, jobId, isLoading, isFetching]);

    useEffect(() => {
        if (jobId && data && !isFirstCall) {
            const {isJobQueued} = data;
            showDialog({
                title: !isJobQueued
                    ? 'Your PDF is ready!'
                    : 'PDF Generating...',
                className: 'confirm-modal',
                message: !isJobQueued
                    ? 'You can now view or download your document.'
                    : 'Please hold on briefly to view your document.',
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        show: true,
                        controlledHideDialog: false,
                        name: 'Close',
                        variant: 'secondary',
                        action: () => {
                            setJobId(null);
                        },
                    },
                    {
                        show: true,
                        controlledHideDialog: false,
                        name: 'View PDF',
                        variant: 'primary',
                        isLoading: !!isJobQueued,
                        action: () => {
                            openUrl(jobId);
                        },
                    },
                ],
            });
        }
    }, [jobId, data, isFirstCall]);

    return {
        viewJobPdf,
    };
};
