import React, {useMemo} from 'react';
import {useJobContext, useAppContext} from 'contexts';
import {PromptButton} from 'shared';
import {JOB_STATUS} from 'components/customer/Dashboard/LastJobButton';
import {useVariationResetButton} from 'shared/components/Header/VariationResetButton';
import {PartialJob} from 'shared/types/PartialJob';
import {useJobSubmitHandler} from 'components/customer/Job/helpers/useJobSubmitHandler';
import {Loader} from 'shared/helpers';
import {useAppSelector} from 'store/customer';
import {getIsSavingProduct} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {shallowEqual} from 'react-redux';
import styled from 'styled-components';
import useUI from 'shared/useUI';

interface SubmitButtonProps {
    left: boolean;
    showLabel: boolean;
}

export const SubmitButton = ({
    left = false,
    showLabel = true,
}: SubmitButtonProps) => {
    const isSavingProduct = useAppSelector(getIsSavingProduct, shallowEqual);
    const {job, isPageDirty, expiredJobPrice} = useJobContext() as PartialJob;
    const {handleJobSubmission, dialog, loader} = useJobSubmitHandler();
    const {noVariations, notRemovableExtraVariatons, extraVariation} =
        useVariationResetButton();
    const {userProfile} = useAppContext();
    const {isSmallScreenLayout} = useUI();

    const submitButtonText = useMemo(() => {
        let buttonText = 'Submit Job';
        if (job.status === JOB_STATUS.JOB_PENDING_CONFIRMATION) {
            if (!noVariations && !extraVariation && job.freightCost == 0) {
                buttonText = 'Confirm Variation Cost';
            } else if (
                noVariations &&
                !extraVariation &&
                job.freightCost == 0
            ) {
                buttonText = 'Approve without Variations';
            } else {
                buttonText = 'Approve Job';
            }
        }

        return buttonText;
    }, [
        job.status,
        noVariations,
        notRemovableExtraVariatons,
        extraVariation,
        job.freightCost,
    ]);

    return (
        <Loader loader={loader || isSavingProduct}>
            {userProfile.inActiveManufacturer == 0 &&
            [0, 11, 19, 5].includes(job.status) &&
            !expiredJobPrice ? (
                <StyledPromptButton
                    $left={left}
                    $isSmallDevice={isSmallScreenLayout}
                    disabled={
                        Object.keys(job).length == 0 ||
                        job.totalProductCount == 0
                    }
                    iconName={left ? 'Submit.svg' : 'Button-Submit.svg'}
                    action={handleJobSubmission}
                    showConfirmation={isPageDirty.length > 0}
                    className={
                        left ? 'submit-button-left' : 'button-blue btn-sm'
                    }
                    title={left ? submitButtonText : null}>
                    {showLabel ? submitButtonText : ''}
                </StyledPromptButton>
            ) : null}
            {dialog}
        </Loader>
    );
};

const StyledPromptButton = styled((props) => <PromptButton {...props} />)<{
    $isSmallDevice?: boolean;
    $left?: boolean;
}>`
    > div:first-child {
        ${({$isSmallDevice, $left}) =>
            $isSmallDevice && $left ? 'width: 45px !important;' : ''}
    }
    > div > img {
        margin: 0 auto !important;
    }

    > span {
        ${({$left}) => ($left ? 'text-align: left;' : '')}
    }
`;
