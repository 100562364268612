import React, {useEffect, useMemo} from 'react';
import {Button} from 'react-bootstrap';
import {Icon, urlPartsJob} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import useUI from 'shared/useUI';
import {useRightSidebar} from 'shared/components/Sidebar';
import {SidebarStatus, SidebarBehaviour} from 'store/customer/UISlice';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {useGetTotalProductCountQuery} from 'components/customer/Job/store/jobApi';
import {useLocation} from 'react-router-dom';

interface CartToggleButtonProps {
    showOnOpen?: boolean;
}

export const CartToggleButton = ({
    showOnOpen = false,
}: CartToggleButtonProps) => {
    const location = useLocation();
    const {rightSidebar, rightSidebarBehaviour} = useUI();
    const {toggle} = useRightSidebar();
    const theme = useTheme();

    const [jobId] = urlPartsJob();

    const isSmallDevice = isDeviceSmall();

    const [animate, setAnimate] = React.useState(false);

    const {data: totalProductCount} = useGetTotalProductCountQuery(
        Number(jobId),
        {
            skip: !jobId,
        }
    );

    const showButton = useMemo(() => {
        // hide this toggle button if not in job page
        if (!location.pathname.startsWith('/v2/job/')) {
            return false;
        }

        // hide if no job id or is on a small device
        if (isSmallDevice || !jobId) {
            return false;
        }

        // hide if force show is off and sidebar is open
        if (
            !showOnOpen &&
            rightSidebarBehaviour == SidebarBehaviour.TOGGLE &&
            rightSidebar === SidebarStatus.OPEN
        ) {
            return false;
        }

        return true;
    }, [
        isSmallDevice,
        jobId,
        rightSidebar,
        showOnOpen,
        location,
        rightSidebarBehaviour,
    ]);

    useEffect(() => {
        if (totalProductCount > 0) {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 1000);
        }
    }, [totalProductCount]);

    if (showButton) {
        return (
            <CartButton
                $showOnOpen={showOnOpen}
                title="Toggle cart visibility"
                onClick={toggle}
                variant="Link"
                className="sidebar-floating-button sidebar-cart-toggle-button">
                <AnimatedIcon
                    $animate={animate}
                    iconName="Cart.svg"
                    color={theme.colors.cartIconColor.main}
                />
                <ProductCount $animate={animate} data-cy="quantity-badge">
                    {totalProductCount ? totalProductCount : 0}
                </ProductCount>
            </CartButton>
        );
    }
};

const CartButton = styled(Button)<{$showOnOpen?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: transparent !important;
    height: 70px !important;

    ${({$showOnOpen}) =>
        $showOnOpen &&
        `
            box-shadow: none !important;

            :hover {
                box-shadow: none !important;
            }
        `}

    > label {
        font-size: 0.8em;
        text-decoration: underline;
    }
`;

const Scale = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
`;

const Wobble = keyframes`
    0%, 20%, 53%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0,0,0);
    }
    40%, 43% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -10px, 0);
    }
    70% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -6px, 0);
    }
    90% {
        transform: translate3d(0,-2px,0);
    }
`;

const AnimatedIcon = styled(Icon)<{$animate: boolean}>`
    ${({$animate}) =>
        $animate
            ? css`
                  animation: ${Wobble} 1s ease-out 0s 1 normal forwards;
              `
            : ''}
`;

export const ProductCount = styled.div<{$animate: boolean}>`
    position: absolute;
    top: 6px;
    right: 10px;
    background-color: #ff7474;
    border-radius: 20px;
    min-width: 20px;
    color: white;
    font-weight: 500;
    height: 20px;
    line-height: 22px;
    font-size: 0.85em;
    width: fit-content;
    padding: 0 5px;
    transition: background-color 350ms ease;

    ${({$animate}) =>
        $animate
            ? css`
                  animation: ${Scale} 1s ease-out;
                  background-color: red;
              `
            : ''}
`;
