import {useEffect, useCallback, DependencyList} from 'react';
import {
    SidebarStatus,
    SidebarBehaviour,
    defaultRightSidebarBehavior,
    defaultLeftSidebarBehavior,
} from 'store/customer/UISlice';
import useUI from 'shared/useUI';

export const useLeftSidebarBehaviour = ({
    behavior,
    setStatusOn = true,
    deps,
}: {
    behavior: SidebarBehaviour;
    setStatusOn?: boolean;
    deps?: DependencyList;
}) => {
    const {setLeftSidebarBehaviour} = useUI();

    useEffect(() => {
        if (setStatusOn) {
            setLeftSidebarBehaviour(behavior);
        }

        return () => {
            setLeftSidebarBehaviour(defaultLeftSidebarBehavior);
        };
    }, [setStatusOn, ...(deps ?? [])]);
};

export const useRightSidebarBehaviour = ({
    behavior,
    setStatusOn = true,
    deps,
    defaultStatusOnCleanUp,
}: {
    behavior: SidebarBehaviour;
    setStatusOn?: boolean;
    deps?: DependencyList;
    defaultStatusOnCleanUp?: SidebarStatus;
}) => {
    const {setRightSidebarBehaviour, setRightSidebar} = useUI();

    useEffect(() => {
        if (setStatusOn) {
            setRightSidebarBehaviour(behavior);
        }

        return () => {
            setRightSidebarBehaviour(defaultRightSidebarBehavior);
            if (!!defaultStatusOnCleanUp) {
                // this will be the default state if provided
                setRightSidebar(defaultStatusOnCleanUp);
            }
        };
    }, [setStatusOn, ...(deps ?? [])]);
};

export const useRightSidebar = () => {
    const {
        setRightSidebar,
        rightSidebarBehaviour,
        isSmallScreenLayout,
        rightSidebar,
    } = useUI();

    const openSmallScreenRightSidebar = () => {
        setRightSidebar(SidebarStatus.OPEN);
    };

    const toggle = useCallback(() => {
        setRightSidebar(
            rightSidebar == SidebarStatus.OPEN
                ? SidebarStatus.CLOSED
                : SidebarStatus.OPEN
        );
    }, [rightSidebar, setRightSidebar]);

    const sidebarCartButtonHandler = useCallback(() => {
        setRightSidebar(
            rightSidebar == SidebarStatus.OPEN
                ? SidebarStatus.CLOSED
                : SidebarStatus.OPEN
        );
    }, [rightSidebar, setRightSidebar]);

    const onMouseEnter = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (rightSidebarBehaviour == SidebarBehaviour.TOGGLE) return;
        if (rightSidebar == SidebarStatus.CLOSED) {
            setRightSidebar(SidebarStatus.OPEN);
        }
    }, [rightSidebar, rightSidebarBehaviour, isSmallScreenLayout]);

    const onMouseLeave = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (rightSidebarBehaviour == SidebarBehaviour.TOGGLE) return;
        if (rightSidebar == SidebarStatus.OPEN) {
            setRightSidebar(SidebarStatus.CLOSED);
        }
    }, [rightSidebar, rightSidebarBehaviour, isSmallScreenLayout]);

    return {
        toggle,
        sidebarCartButtonHandler,
        onMouseEnter,
        onMouseLeave,
        openSmallScreenRightSidebar,
    };
};

export const useLeftSidebar = () => {
    const {
        setLeftSidebar,
        isSmallScreenLayout,
        leftSidebar,
        leftSidebarBehaviour,
    } = useUI();

    const toggle = useCallback(() => {
        setLeftSidebar(
            leftSidebar == SidebarStatus.CLOSED
                ? SidebarStatus.OPEN
                : SidebarStatus.CLOSED
        );
    }, [leftSidebar, setLeftSidebar]);

    const onMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (!isSmallScreenLayout) return;

            if (
                (e.target as Element).classList.contains('left-sidebar-toggle')
            ) {
                return;
            }

            if (leftSidebar == SidebarStatus.CLOSED) {
                setLeftSidebar(SidebarStatus.OPEN);
            }
        },
        [leftSidebar, leftSidebarBehaviour, isSmallScreenLayout]
    );

    const onMouseLeave = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (leftSidebar == SidebarStatus.OPEN) {
            setLeftSidebar(SidebarStatus.CLOSED);
        }
    }, [leftSidebar, leftSidebarBehaviour, isSmallScreenLayout]);

    return {
        toggle,
        onMouseEnter,
        onMouseLeave,
    };
};
