import {css} from 'styled-components';

interface Size {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
}

export const size: Size = {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1440,
};

// hoc to create media query
const createMediaQuery = (query: string) => {
    const mediaQuery = (inner: TemplateStringsArray | string) => {
        return css`
            @media ${query} {
                ${inner}
            }
        `;
    };

    return mediaQuery;
};

// Please add more as required
export const mobile = createMediaQuery(`(max-width: ${size.sm}px)`);
export const tablet = createMediaQuery(
    `(min-width: ${size.sm}px) and (max-width: ${size.md}px)`
);
