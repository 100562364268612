import {UserCheck} from './UserCheck';
import {ManufacturerCheck} from './ManufacturerCheck';
import {UserMessages} from './UserMessages';
import {ManufacturerDisabledProperties} from './ManufacturerDisabledProperties';
import {Header} from './components/Header/Header';
import {SidebarRight} from './components/Header/SidebarRight';
import {SidebarLeft} from './components/Header/SidebarLeft';
import {JobMenuItem} from './components/Header/JobMenuItem';
import {CBCTable} from './components/Table/Table';
import ErrorBoundary from './ErrorBoundary';
import {FourZeroFour} from './FourZeroFour';
import {FourZeroFourRaw} from './FourZeroFourRaw';
import {Suspended} from './Suspended';
import {
    useConfirmationDialog,
    ConfirmationDialog,
} from './components/ConfirmationDialog/ConfirmationDialog';
import {NavigatorConfirmationDialog} from './components/ConfirmationDialog/NavigatorConfirmationDialog';
import {Wizard} from './components/Wizard/Wizard';
import {Dropdown} from './components/Forms/Dropdown';
import {FormControl} from './components/Forms/FormControl';
import {ImageSlider} from './components/ImageSlider/ImageSlider';
import {SidebarRightRoom} from './components/Header/SidebarRightRooms';
import {SidebarRightProducts} from './components/Header/SidebarRightProducts';
import {SidebarRightBenchtop} from './components/Header/SidebarRightBenchtop';
import {SidebarRightSundries} from './components/Header/SidebarRightSundries';
import {CategoriesAndItems} from './components/Product/CategoriesAndItems';
import {Search} from './components/Product/Search';
import {Categories} from './components/Product/Categories';
import {Items} from './components/Product/Items';
import {Number} from './components/Forms/Number';
import {Size} from './components/Forms/Size';
import ComponentPainter, {COMPONENT_TYPES} from './shapes/ComponentPainter';
import {MobileMenu} from './components/Header/MobileMenu';
import {Cart, CartContent} from './components/Header/Cart';
import {DropdownDecorator} from './components/Forms/DropdownDecorator';
import {FIELD_TYPES, Field} from './components/Forms/Field';
import {Boolean} from './components/Forms/Boolean';
import {Radio} from './components/Forms/Radio';
import {
    DynamicRadio,
    getIncludeDoorFacesLabel,
    getIncludeDoorFacesImage,
} from './components/Forms/DynamicRadio';
import {DynamicRadioMobile} from './components/Forms/DynamicRadioMobile';
import {Appliance} from './components/Forms/Applicance';
import {RecentItems} from './components/Product/RecentItems';

import MoreInfoData from './static/MoreInfoData.json';
import JobColumns from './static/JobColumns.json';
import {PromptButton, DIRTY_PAGE} from './PromptButton';
import {MoreInfo} from './components/MoreInfo/MoreInfo';
import {WYSIWYGEditor} from './WYSIWYG';
import {JobRoute} from './auth/JobRoute';
import {ProcessingJobRoute} from './auth/ProcessingJobRoute';
import {Messages} from './components/Header/Messages';
import HTTPHandler from './service/HTTPHandler';
import CacheHandler from './service/CacheHandler';
import IDBHandler from './service/IDBHandler';
import HandlerBuilder from './service/HandlerBuilder';
import {MobileWrapper} from './components/Mobile/MobileWrapper';
import {validateProducts} from './validator';
import {Password} from './components/Forms/Password';
import {OverlayTrigger} from './components/OverlayTrigger';
import {MIMETypes} from './MIMETypes';
import DropdownListItem, {
    DropdownType,
} from './components/Forms/DropdownListItem';
import DropdownMenu from './components/Forms/DropdownMenu';
import {SearchBar} from './components/Header/SearchBar';
import {Configuration} from './components/Analytics/Configuration';
import {PageEvents} from './components/Analytics/PageEvents';
import {useNumberInputScrollDisabler} from './NumberInputScrollDisabler';

import type {Handler} from './service/Handler';

const Analytics = {
    Configuration,
    PageEvents,
};

export {
    UserCheck,
    ManufacturerCheck,
    UserMessages,
    ManufacturerDisabledProperties,
    Header,
    SearchBar,
    SidebarRight,
    SidebarLeft,
    JobMenuItem,
    CBCTable,
    ErrorBoundary,
    FourZeroFour,
    FourZeroFourRaw,
    Suspended,
    MoreInfoData,
    JobColumns,
    Wizard,
    useConfirmationDialog,
    ConfirmationDialog,
    NavigatorConfirmationDialog,
    Dropdown,
    FormControl,
    ImageSlider,
    SidebarRightRoom,
    SidebarRightProducts,
    SidebarRightBenchtop,
    SidebarRightSundries,
    CategoriesAndItems,
    Search,
    Categories,
    Items,
    Number,
    Size,
    ComponentPainter,
    COMPONENT_TYPES,
    MobileMenu,
    Cart,
    CartContent,
    DropdownDecorator,
    FIELD_TYPES,
    Field,
    Boolean,
    Appliance,
    RecentItems,
    PromptButton,
    DIRTY_PAGE,
    MoreInfo,
    WYSIWYGEditor,
    ProcessingJobRoute,
    JobRoute,
    Messages,
    HTTPHandler,
    CacheHandler,
    IDBHandler,
    HandlerBuilder,
    MobileWrapper,
    Radio,
    validateProducts,
    Password,
    OverlayTrigger,
    MIMETypes,
    DropdownListItem,
    DropdownType,
    DropdownMenu,
    Analytics,
    useNumberInputScrollDisabler,
    DynamicRadio,
    getIncludeDoorFacesLabel,
    getIncludeDoorFacesImage,
    DynamicRadioMobile,
};

export type {Handler};
