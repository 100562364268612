import {useEffect, useRef} from 'react';
import useUI from 'shared/useUI';
import {SidebarBehaviour, SidebarStatus} from 'store/customer/UISlice';

export const useSidebarStateToggle = () => {
    const {
        rightSidebarBehaviour,
        setLeftSidebar,
        setRightSidebar,
        setRightSidebarBehaviour,
        layoutEffectDone,
    } = useUI();

    const isUnmounted = useRef(false);

    useEffect(() => {
        if (!layoutEffectDone) return;

        if (!isUnmounted.current) {
            setRightSidebarBehaviour(SidebarBehaviour.SOFT_OPEN);
            setLeftSidebar(SidebarStatus.CLOSED);
            setRightSidebar(SidebarStatus.CLOSED);
        }

        const handleBeforeUnload = () => {
            isUnmounted.current = true;
            setRightSidebarBehaviour(SidebarBehaviour.TOGGLE);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            setRightSidebarBehaviour(SidebarBehaviour.TOGGLE);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [layoutEffectDone, rightSidebarBehaviour]);
};
