import React, {useState, useMemo, useRef, useCallback, ReactNode} from 'react';
import {Button} from 'react-bootstrap';
import {Icon} from './helpers';
import {ConfirmationDialog} from 'shared';

export const DIRTY_PAGE = {
    title: 'Changes not yet saved',
    message:
        '<p>Are you sure you want to leave? There are item/s in your cart which have had the quantity changed but have not been saved. If you proceed, these changes will be lost.</p>',
};

interface PromptButtonProps {
    iconName: string;
    action: (e: React.MouseEvent) => void | Promise<void>;
    showConfirmation: boolean;
    className?: string;
    disabled?: boolean;
    children?: ReactNode;
    title?: string;
}

export const PromptButton = ({
    iconName,
    action,
    showConfirmation,
    className,
    disabled = false,
    title = null,
    children = null,
}: PromptButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const ogEvent = useRef<React.MouseEvent<HTMLElement, MouseEvent>>();

    const runAction = useCallback(
        (e: React.MouseEvent) => {
            if (!action) {
                return;
            }

            void action(e);
        },
        [action]
    );

    const clickHandler: React.MouseEventHandler<HTMLElement> = useCallback(
        (e) => {
            e.persist();
            ogEvent.current = e;
            if (showConfirmation) {
                setShowModal(true);
            } else {
                runAction(e);
            }
        },
        [runAction, showConfirmation]
    );

    const confirmHandler = useCallback(() => setShowModal(false), []);

    const options = useMemo(
        () => ({
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Proceed',
                    show: true,
                    variant: 'danger',
                    action: () => {
                        runAction(ogEvent.current);
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                    action: () => {
                        setShowModal(false);
                    },
                },
            ],
        }),
        [runAction]
    );

    return (
        <>
            <Button
                variant="link"
                disabled={disabled}
                onClick={clickHandler}
                className={className}>
                <div>
                    <Icon iconName={iconName} title={title} />
                </div>
                {children ? <span>{children}</span> : null}
            </Button>
            <ConfirmationDialog
                title={DIRTY_PAGE.title}
                message={DIRTY_PAGE.message}
                show={showModal}
                options={options}
                hideDialog={confirmHandler}
                backdrop="static"
            />
        </>
    );
};
