import {Menu as MenuInterface} from 'config/entity/menu/HeaderMenu';
import React, {memo} from 'react';
import {Dropdown} from 'react-bootstrap';
import {MenuItem} from 'shared/components/HeaderMenu/MenuItem';

export interface MenuProps {
    menu: MenuInterface[];
    alignRight?: boolean;
    className?: string;
}

export const Menu = memo(({menu, alignRight = false, className}: MenuProps) => {
    return (
        <Dropdown.Menu
            align={alignRight ? 'end' : 'start'}
            className={className}>
            {menu
                ? menu.map((item) => {
                      return <MenuItem key={item.name} menu={item} />;
                  })
                : null}
        </Dropdown.Menu>
    );
});

Menu.displayName = 'Menu';
