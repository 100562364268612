import React, {useRef} from 'react';
import {SidebarBehaviour, SidebarStatus} from 'store/customer/UISlice';
import styled from 'styled-components';

export type Anchor = 'LEFT' | 'RIGHT';

export interface SidebarProps {
    children?: React.ReactElement;
    className?: string;
    header?: React.ReactElement;
    style?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    showOverlays?: boolean;
    status?: SidebarStatus;
    behaviour?: SidebarBehaviour;
    anchor?: Anchor;
    initialPosition?: boolean;
    sidebarId?: string;
    softOpen?: boolean;
    partialClose?: boolean;
    partialCloseWidth?: number;
    onMouseEnter?: (_: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onMouseLeave?: (_: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Sidebar = ({
    children,
    className,
    header,
    style,
    showOverlays,
    status,
    behaviour,
    anchor = 'RIGHT',
    initialPosition,
    sidebarId,
    softOpen,
    partialClose,
    partialCloseWidth = 100,
    onMouseEnter,
    onMouseLeave,
}: SidebarProps) => {
    const sidebarRef = useRef<HTMLElement>(null);

    return (
        <SidebarComponent
            ref={sidebarRef}
            className={'sidebar ' + (className ?? '')}
            id={sidebarId}
            style={style}
            $status={status}
            $behaviour={behaviour}
            $initialPosition={initialPosition}
            $softOpen={softOpen}
            $anchor={anchor}
            $partialClose={partialClose}
            $partialCloseWidth={partialCloseWidth}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            {showOverlays ? <Overlay /> : null}
            {header ? (
                <SidebarHeader className="sidebar-header">
                    {header}
                </SidebarHeader>
            ) : null}
            {children}
        </SidebarComponent>
    );
};

export type SidebarComponentProps = {
    $status?: SidebarStatus;
    $behaviour?: SidebarBehaviour;
    $initialPosition?: boolean;
    $softOpen?: boolean;
    $anchor?: Anchor;
    $partialClose?: boolean;
    $partialCloseWidth: number;
};

export const SidebarComponent = styled.section<SidebarComponentProps>`
    &&& {
        ${({$status, $behaviour}) => {
            if (
                $status === SidebarStatus.CLOSED ||
                $behaviour === SidebarBehaviour.INVISIBLE
            )
                return 'display: none;';

            return '';
        }}
        ${({
            $status,
            $behaviour,
            $softOpen,
            $anchor,
            $partialClose,
            $partialCloseWidth,
        }) => {
            const leftAnchor = $anchor === 'LEFT';
            const isOpen = $status === SidebarStatus.OPEN;
            const direction = leftAnchor ? 'left' : 'right';

            if ($softOpen) return 'position: fixed;';
            if ($behaviour === SidebarBehaviour.TOGGLE)
                return `position: initial;  ${
                    isOpen
                        ? ``
                        : $partialClose
                        ? `${direction}: 0; width: ${$partialCloseWidth}px; display: flex;`
                        : ''
                }
        `;
        }}
    }
`;

export const SidebarHeader = styled.section`
    display: flex;
    align-items: center;
    font-size: 12px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0 0 7px 0px #999999;
    height: 70px !important;
`;

export const Overlay = styled.div`
    position: absolute;
    width: 100%;
    opacity: 0.7;
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #333;
    overflow-x: hidden;
    transition: 0.2s;
`;

export default Sidebar;
